import React from "react";
import theme from "theme";
import { Theme, Image, Text, Icon, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdLocalPhone, MdExtension, MdImportantDevices } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contact"} />
		<Helmet>
			<title>
				Pristine Shine El Araba Yıkama
			</title>
			<meta name={"description"} content={"Her Detayda Mükemmellik"} />
			<meta property={"og:title"} content={"Pristine Shine El Araba Yıkama"} />
			<meta property={"og:description"} content={"Her Detayda Mükemmellik"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/668d05098d4a0c00203182a2/images/1-4.jpeg?v=2024-07-09T11:20:49.950Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/668d05098d4a0c00203182a2/images/1-4.jpeg?v=2024-07-09T11:20:49.950Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/668d05098d4a0c00203182a2/images/1-4.jpeg?v=2024-07-09T11:20:49.950Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/668d05098d4a0c00203182a2/images/1-4.jpeg?v=2024-07-09T11:20:49.950Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/668d05098d4a0c00203182a2/images/1-4.jpeg?v=2024-07-09T11:20:49.950Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/668d05098d4a0c00203182a2/images/1-4.jpeg?v=2024-07-09T11:20:49.950Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/668d05098d4a0c00203182a2/images/1-4.jpeg?v=2024-07-09T11:20:49.950Z"} />
			<meta name={"msapplication-TileColor"} content={"#000848"} />
		</Helmet>
		<Components.Header />
		<Image
			md-top="300px"
			lg-top="0px"
			src="https://uploads.quarkly.io/6123949e957019001e284458/images/backgroung-hero.svg?v=2021-09-21T22:07:12.314Z"
			position="absolute"
			right="0px"
			bottom="auto"
			top="0px"
			z-index="1"
			lg-right="-200px"
			md-width="600px"
			height="694px"
			left="auto"
			width="1059px"
		/>
		<Components.Hero12>
			<Override slot="text1">
				Temasta olmak
			</Override>
			<Override slot="image" src="https://uploads.quarkly.io/668d05098d4a0c00203182a2/images/1-2.jpeg?v=2024-07-09T11:20:49.946Z" />
		</Components.Hero12>
		<Section padding="80px 0 90px 0" background="--color-light" quarkly-title="Contacts-2">
			<Override slot="SectionContent" lg-align-items="center" />
			<Text
				lg-padding="0px 0px 0px 0px"
				sm-font="normal 700 36px/1.2 --fontFamily-sans"
				margin="0px 0px 18px 0px"
				font="normal 600 42px/1.2 --fontFamily-sans"
				color="--darkL1"
				text-align="center"
				padding="0px 0px 0px 0px"
				lg-width="100%"
				lg-margin="0px 0px 25px 0px"
				lg-max-width="720px"
				md-padding="0px 20px 0px 20px"
				sm-text-align="left"
				sm-padding="0px 10px 0px 10px"
			>
				Farkı Yaşayın
			</Text>
			<Text
				margin="0px 20% 50px 20%"
				font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
				lg-margin="0px 0 50px 0px"
				text-align="center"
				sm-margin="0px 0 35px 0px"
				lg-max-width="720px"
				md-padding="0px 20px 0px 20px"
				sm-text-align="left"
				sm-padding="0px 10px 0px 10px"
			>
				Arabanıza hak ettiği bakımı vermeye hazır mısınız? Pristine Shine Hand Car Wash'u ziyaret edin ve uzman hizmetlerimizin yaratabileceği farkı keşfedin. Aracınızın daha önce hiç olmadığı kadar parlak olmasını sabırsızlıkla bekliyoruz.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="25px 25px"
				md-grid-template-columns="1fr"
				flex-direction="column"
				align-items="center"
				lg-align-items="flex-start"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					padding="25px 20px 25px 20px"
					border-color="rgba(74, 74, 108, 0.4)"
					border-radius="25px"
					lg-flex-direction="column"
					width="600px"
					sm-padding="15px 10px 15px 10px"
					md-width="100%"
				>
					<Icon
						category="md"
						icon={MdLocalPhone}
						size="44px"
						color="--light"
						margin="0px 25px 0px 0px"
						lg-margin="0px 0 28px 0px"
						background="--color-green"
						padding="10px 10px 10px 10px"
						border-radius="4px"
						sm-margin="0px 0 18px 0px"
					/>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 12px 0px" color="--darkL1" font="normal 500 22px/1.2 --fontFamily-sans">
							İletişim bilgileri:
						</Text>
						<Text margin="0px 0px 20px 0px" font="--base" color="#3e4148">
							+905399405353
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					padding="25px 20px 25px 20px"
					border-color="rgba(74, 74, 108, 0.4)"
					border-radius="25px"
					lg-flex-direction="column"
					width="600px"
					sm-padding="15px 10px 15px 10px"
					md-width="100%"
				>
					<Icon
						category="md"
						icon={MdExtension}
						size="44px"
						color="--light"
						margin="0px 25px 0px 0px"
						lg-margin="0px 0 28px 0px"
						background="--color-green"
						padding="10px 10px 10px 10px"
						border-radius="4px"
						sm-margin="0px 0 18px 0px"
					/>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 12px 0px" color="--darkL1" font="normal 500 22px/1.2 --fontFamily-sans">
							Adres
						</Text>
						<Text margin="0px 0px 20px 0px" font="--base" color="#3e4148">
							Ulus, Okul Yolu Sk. 17/A, 34340 Beşiktaş/İstanbul, Türkiye
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					padding="25px 20px 25px 20px"
					border-color="rgba(74, 74, 108, 0.4)"
					border-radius="25px"
					lg-flex-direction="column"
					width="600px"
					sm-padding="15px 10px 15px 10px"
					md-width="100%"
				>
					<Icon
						category="md"
						icon={MdImportantDevices}
						size="44px"
						color="--light"
						margin="0px 25px 0px 0px"
						lg-margin="0px 0 28px 0px"
						background="--color-green"
						padding="10px 10px 10px 10px"
						border-radius="4px"
						sm-margin="0px 0 18px 0px"
					/>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 12px 0px" color="--darkL1" font="normal 500 22px/1.2 --fontFamily-sans">
							Çalışma saatleri:
						</Text>
						<Text margin="0px 0px 20px 0px" font="--base" color="#3e4148">
							Pazartesiden cumaya: 10:00 - 19:00 arası
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="link3">
				+905399405353
				<br />
				Ulus, Okul Yolu Sk. 17/A, 34340 Beşiktaş/İstanbul, Türkiye
			</Override>
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6123949e957019001e284456"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});